
import { computed, defineComponent } from 'vue';

export default defineComponent({
	props: {
		isText: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'md',
			validator: (val: string) => {
				return ['none', 'xs', 'sm', 'md', 'lg', 'xl'].includes(val);
			},
		},
		shadow: {
			type: Boolean,
			default: false,
		},
		shadowColor: {
			type: String,
			default: 'transparent',
			validator: (val: string) => {
				return ['transparent', 'primary'].includes(val);
			},
		},
		textColor: {
			type: String,
			default: 'theme',
		},
		fontSize: {
			type: String,
			default: 'md',
			validator: (val: string) => {
				return ['sm', 'md', 'lg'].includes(val);
			},
		},
		fontWeight: {
			type: Number,
			default: 400,
			validator: (val: number) => {
				return [100, 200, 300, 400, 500, 600, 700, 800, 900, 950].includes(val);
			},
		},
		bgColor: {
			type: String,
			default: 'theme',
			validator: (val: string) => {
				return ['transparent', 'primary', 'black', 'theme'].includes(val);
			},
		},
	},
	setup(props) {
		const classList = computed(() => {
			const nonTextClass = props.isText ? '' : 'btn ';
			const buttonSize = `btn-${props.size} `;
			const shadow = props.shadow ? 'shadow ' : '';
			const shadowColor = props.shadow ? `shadow-${props.shadowColor} ` : '';
			const textColor = `text-${props.textColor} `;
			const fontSize = `font-${props.fontSize} `;
			const fontWeight = `font-${props.fontWeight} `;
			const bgColor = `bg-${props.bgColor}`;
			return `${nonTextClass}${buttonSize}${shadow}${shadowColor}${textColor}${fontSize}${fontWeight}${bgColor}`.trim();
		});

		return {
			classList,
		};
	},
});
