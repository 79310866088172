import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e899a82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-select-item" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.$slots.customItem)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          (_ctx.multiple)
            ? (_openBlock(), _createBlock(_component_BaseCheckbox, {
                key: 0,
                modelValue: _ctx.checkboxValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkboxValue) = $event)),
                class: "mr-2 base-select-item__checkbox"
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.displayValue), 1)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "customItem", {}, undefined, true)
  ]))
}