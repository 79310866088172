import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16947c55"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "theme-switcer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DarkThemeIcon = _resolveComponent("DarkThemeIcon")!
  const _component_LightThemeIcon = _resolveComponent("LightThemeIcon")!

  return (_ctx.config.THEME_SWITCH_ENABLED)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isDarkTheme)
          ? (_openBlock(), _createBlock(_component_DarkThemeIcon, {
              key: 0,
              class: _normalizeClass(_ctx.darkThemeClass),
              onClick: _ctx.toggleTheme
            }, null, 8, ["class", "onClick"]))
          : (_openBlock(), _createBlock(_component_LightThemeIcon, {
              key: 1,
              class: _normalizeClass(_ctx.lightThemeClass),
              onClick: _ctx.toggleTheme
            }, null, 8, ["class", "onClick"]))
      ]))
    : _createCommentVNode("", true)
}