import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_RegisterForm = _resolveComponent("RegisterForm")!
  const _component_ForgotPasswordForm = _resolveComponent("ForgotPasswordForm")!
  const _component_NewPasswordForm = _resolveComponent("NewPasswordForm")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    active: _ctx.active,
    "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:active', false)))
  }, {
    default: _withCtx(() => [
      (_ctx.currentComponent === _ctx.FormTypes.Login)
        ? (_openBlock(), _createBlock(_component_LoginForm, {
            key: 0,
            forgotPasswordLinkClick: _ctx.openForgotPassword,
            registerLinkClick: _ctx.openRegister
          }, null, 8, ["forgotPasswordLinkClick", "registerLinkClick"]))
        : _createCommentVNode("", true),
      (_ctx.currentComponent === _ctx.FormTypes.Register)
        ? (_openBlock(), _createBlock(_component_RegisterForm, {
            key: 1,
            loginLinkClick: _ctx.openLogin
          }, null, 8, ["loginLinkClick"]))
        : _createCommentVNode("", true),
      (_ctx.currentComponent === _ctx.FormTypes.ForgotPassword)
        ? (_openBlock(), _createBlock(_component_ForgotPasswordForm, {
            key: 2,
            loginLinkClick: _ctx.openLogin,
            openNewPasswordForm: _ctx.openNewPassword
          }, null, 8, ["loginLinkClick", "openNewPasswordForm"]))
        : _createCommentVNode("", true),
      (_ctx.currentComponent === _ctx.FormTypes.NewPassword)
        ? (_openBlock(), _createBlock(_component_NewPasswordForm, { key: 3 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["active"]))
}