
import { defineComponent } from 'vue';
import { ClickFunction } from '../../types/functions';
import BaseButton from '../Base/BaseButton.vue';

export default defineComponent({
	name: 'LinkAndButtonGroup',
	components: { BaseButton },
	props: {
		linkText: {
			type: String,
			required: true,
		},
		linkClick: {
			type: ClickFunction,
			required: true,
		},
		buttonText: {
			type: String,
			required: true,
		},
		buttonClick: {
			type: ClickFunction,
			reuired: true,
		},
	},
	setup() {
		return {};
	},
});
