
import { defineComponent, Ref, ref, watchEffect } from 'vue';
import { ClickFunction } from '../../types/functions';
import { FormTypes } from '../LoginRegistedForms/formTypes';
import BaseDialog from '../Base/BaseDialog.vue';
import LoginForm from '../LoginRegistedForms/LoginForm.vue';
import RegisterForm from '../LoginRegistedForms/RegisterForm.vue';
import ForgotPasswordForm from '../LoginRegistedForms/ForgotPasswordForm.vue';
import NewPasswordForm from '../LoginRegistedForms/NewPasswordForm.vue';

type ClickFunctionType = ReturnType<typeof ClickFunction>;

export default defineComponent({
	components: {
		BaseDialog,
		LoginForm,
		RegisterForm,
		ForgotPasswordForm,
		NewPasswordForm,
	},
	props: {
		active: {
			default: false,
			type: Boolean,
		},
	},
	emits: ['update:active'],
	setup(props) {
		const currentComponent: Ref<FormTypes> = ref(FormTypes.Login);

		const openForgotPassword: ClickFunctionType = () => {
			currentComponent.value = FormTypes.ForgotPassword;
		};

		const openRegister: ClickFunctionType = () => {
			currentComponent.value = FormTypes.Register;
		};

		const openLogin: ClickFunctionType = () => {
			currentComponent.value = FormTypes.Login;
		};

		const openNewPassword = () => {
			currentComponent.value = FormTypes.NewPassword;
		};

		watchEffect(() => {
			if (!props.active) {
				currentComponent.value = FormTypes.Login;
			}
		});

		return {
			FormTypes,
			currentComponent,
			openForgotPassword,
			openRegister,
			openLogin,
			openNewPassword,
		};
	},
});
