
import { computed, defineComponent, ref } from 'vue';
import BasePaginator from './Base/BasePaginator.vue';
import HeroEnterIcon from './Icons/HeroEnterIcon.vue';
import BaseButton from './Base/BaseButton.vue';
import HeroTradistTIcon from './Icons/HeroTradistTIcon.vue';
import BaseDivider from './Base/BaseDivider.vue';
import { config } from '../config';
import { apiService } from '../services/ApiService';
import { useRouter } from 'vue-router';
import { shorthenedSellerAddress, weiToEther } from '../utils';

export default defineComponent({
	setup() {
		const heroes = ref([]);
		const count = ref(0);
		const router = useRouter();

		(async function getHeroes() {
			const { data } = await apiService.getHeroes();
			count.value = data.count;
			heroes.value = data.heroes;
		})();

		const currentPage = ref(0);

		const currentHero = computed(() => {
			return heroes.value[currentPage.value] || {};
		});

		const currentPrice = computed(() => {
			return currentHero.value.erc721.price
				? `${weiToEther(currentHero.value.erc721.price)} ETH`
				: '';
		});

		const handleViewItem = () => {
			router.push(
				`/token/${currentHero.value.erc721.contractAddress}/${currentHero.value.erc721.tokenId}`,
			);
		};

		const changePage = (idx) => {
			let a = currentPage.value;
			a += idx;
			if (a === -1) currentPage.value = count.value - 1;
			else if (a === count.value) currentPage.value = 0;
			else currentPage.value += idx;
		};

		return {
			config,
			count,
			currentPage,
			currentHero,
			currentPrice,
			handleViewItem,
			changePage,
			shorthenedSellerAddress,
			weiToEther,
		};
	},
	components: {
		BasePaginator,
		HeroEnterIcon,
		BaseButton,
		HeroTradistTIcon,
		BaseDivider,
	},
});
