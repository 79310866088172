<template>
	<svg
		class="mx-auto"
		width="17px"
		height="14px"
		viewBox="0 0 17 14"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g
				transform="translate(-1230.000000, -3807.000000)"
				class="c1-primary"
				fill="#CAFF15"
				fill-rule="nonzero"
			>
				<g transform="translate(0.000000, 3574.000000)">
					<polygon
						transform="translate(1238.166679, 240.000000) scale(-1, 1) translate(-1238.166679, -240.000000) "
						points="1246.33336 238.833303 1234.66668 238.833303 1238.75003 234.749991 1237.00004 233 1230 240 1237 247 1238.74999 245.250009 1234.66668 241.166661 1246.33336 241.166661"
					></polygon>
				</g>
			</g>
		</g>
	</svg>
</template>
