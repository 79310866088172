
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		text: {
			type: String,
			required: true,
		},
	},
	setup() {
		return {};
	},
});
