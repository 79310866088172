import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-680593b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 1,
  class: "custom-select__dropdown"
}
const _hoisted_3 = { class: "custom-select__dropdown--item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseSelectItem = _resolveComponent("BaseSelectItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "custom-select flex items-center justify-between",
      tabindex: "0",
      onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.openDropdownInputFocus && _ctx.openDropdownInputFocus(...args)), ["enter"])),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openDropdownInputFocus && _ctx.openDropdownInputFocus(...args)))
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.displayValue), 1),
      _createVNode(_component_ChevronDownIcon, { class: "custom-select--icon" })
    ], 32),
    (_ctx.isDropdownOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "custom-select__dropdown--background",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeDropdown && _ctx.closeDropdown(...args)))
        }))
      : _createCommentVNode("", true),
    (_ctx.isDropdownOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseSelectItem, null, {
              customItem: _withCtx(() => [
                _createVNode(_component_BaseInput, {
                  modelValue: _ctx.filterInputValue,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterInputValue) = $event)),
                  placeholder: "Search",
                  class: "custom-select__dropdown--search",
                  ref: "searchInput",
                  onKeydown: _withKeys(_ctx.handleSearchInputEnterKey, ["enter"])
                }, null, 8, ["modelValue", "onKeydown"])
              ]),
              _: 1
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fitleredData, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "custom-select__dropdown--item",
              key: index
            }, [
              _renderSlot(_ctx.$slots, "default", {
                multiple: _ctx.multiple,
                item: item,
                index: index,
                displayKey: _ctx.displayKey
              }, undefined, true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}