
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'BaseCheckbox',
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		error: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const change = (e) => {
			emit('update:modelValue', e.target.checked);
		};

		return {
			change,
		};
	},
});
