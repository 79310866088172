
import { computed, defineComponent } from 'vue';
import { useTheme } from '../../composables/useTheme';

export default defineComponent({
	props: {
		themeResponsive: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const { isDarkTheme } = useTheme();

		const white = '#FFFFFF';

		const whiteFiller = computed(() => {
			if (props.themeResponsive) {
				return isDarkTheme.value ? white : '#43454E';
			}
			return white;
		});

		return {
			whiteFiller,
		};
	},
});
