import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c7e408d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "material-input-group--error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.divClasses)
  }, [
    _createElementVNode("label", {
      for: _ctx.inputId,
      class: _normalizeClass([_ctx.labelClasses, "material-input-group--label text-xs"])
    }, _toDisplayString(_ctx.label), 11, _hoisted_1),
    _createVNode(_component_BaseInput, _mergeProps({
      id: _ctx.inputId,
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
    }, _ctx.$attrs, {
      class: [_ctx.error ? 'material-input-group--input--error' : '', "material-input-group--input"]
    }), null, 16, ["id", "modelValue", "class"]),
    (_ctx.error && _ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 2))
}