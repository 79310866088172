import { ethers, Signer } from 'ethers';
import {
	TradistMarketplace,
	TradistMarketplace__factory,
} from '../../../../contracts/types/ethers';
export class Marketplace {
	contract: TradistMarketplace;
	isInited: boolean;
	constructor(address: string, signer: Signer) {
		this.contract = TradistMarketplace__factory.connect(address, signer);
	}

	mint(
		name: string,
		keys: string[],
		values: string[],
		tokenUri: string,
		contractOnChainId: number,
	) {
		return this.contract.mintToken(
			name,
			keys,
			values,
			tokenUri,
			`${contractOnChainId}`,
		);
	}

	listToken(
		tokenId: string,
		contractId: string,
		price: string,
		options = { value: ethers.utils.parseEther('0.001') },
	) {
		return this.contract.listToken(
			tokenId,
			contractId,
			ethers.utils.parseEther(price),
			options,
		);
	}

	buyToken(listingId: string, options = {}) {
		return this.contract.buyToken(listingId, options);
	}
}
