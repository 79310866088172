<template>
	<svg
		width="18px"
		height="15px"
		viewBox="0 0 18 15"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g
				transform="translate(-1348.000000, -1917.000000)"
				fill="#353945"
				fill-rule="nonzero"
			>
				<g transform="translate(1321.777570, 1917.666000)">
					<polygon
						transform="translate(35.166679, 7.000000) scale(-1, 1) translate(-35.166679, -7.000000) "
						points="43.3333575 5.83330316 31.6666787 5.83330316 35.7500272 1.74999095 34.0000362 0 27 7 34 14 35.7499909 12.2500091 31.6666787 8.16666063 43.3333575 8.16666063"
					></polygon>
				</g>
			</g>
		</g>
	</svg>
</template>
