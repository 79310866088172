
import { defineComponent } from 'vue';
import LeftArrowIcon from '../Icons/LeftArrowIcon.vue';
import RightArrowIcon from '../Icons/RightArrowIcon.vue';

export default defineComponent({
	setup(props, { emit }) {
		const previousPage = () => {
			emit('previousPage');
		};

		const nextPage = () => {
			emit('nextPage');
		};
		return {
			previousPage,
			nextPage,
		};
	},
	components: { LeftArrowIcon, RightArrowIcon },
});
