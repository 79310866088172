
import { defineComponent } from 'vue';
import InstagramIcon from '../Icons/InstagramIcon.vue';
import FacebookIcon from '../Icons/FacebookIcon.vue';
import TwitterIcon from '../Icons/TwitterIcon.vue';
import YoutubeIcon from '../Icons/YoutubeIcon.vue';
import BaseDivider from '../Base/BaseDivider.vue';
import TradistIcon from '../Icons/TradistIcon.vue';
import QrCodeIcon from '../Icons/QrCodeIcon.vue';
import NewsletterButton from '../Buttons/NewsletterButton.vue';
import BaseInput from '../Base/BaseInput.vue';
import ThemeSwitcher from '../ThemeSwitcher.vue';

export default defineComponent({
	name: 'TheFooter',
	components: {
		InstagramIcon,
		FacebookIcon,
		TwitterIcon,
		YoutubeIcon,
		BaseDivider,
		TradistIcon,
		QrCodeIcon,
		NewsletterButton,
		BaseInput,
		ThemeSwitcher,
	},
});
