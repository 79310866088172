
import { computed, defineComponent } from 'vue';
import BaseCheckbox from './BaseCheckbox.vue';

export default defineComponent({
	props: {
		text: {
			type: String,
			default: '',
		},
		item: {
			type: Object,
			default: () => ({}),
		},
		displayKey: {
			type: String,
			default: '',
		},
		uniqueKey: {
			type: [Number, String],
			default: '',
		},
		multiple: {
			type: Boolean,
			default: true,
		},
		selectedItems: {
			type: Array,
			default: () => [],
		},
	},
	emits: ['selected', 'unselected'],
	setup(props, { emit }) {
		const displayValue = computed(() => {
			return props.text || props.item[props.displayKey];
		});

		const checkboxValue = computed<boolean>({
			get() {
				return !!props.selectedItems.find(
					(item) =>
						item[props.uniqueKey] === props.item[props.uniqueKey] &&
						item[props.displayKey] === props.item[props.displayKey],
				);
			},
			set(value) {
				value ? emit('selected', props.item) : emit('unselected', props.item);
			},
		});

		return { displayValue, checkboxValue };
	},
	components: { BaseCheckbox },
});
