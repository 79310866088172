
import { defineComponent } from 'vue';
import TradistIcon from '../Icons/TradistIcon.vue';
import HollowXIcon from '../Icons/HollowXIcon.vue';

export default defineComponent({
	props: {
		active: {
			default: false,
			type: Boolean,
		},
	},
	emits: ['update:active'],
	setup(props, { emit }) {
		const closeDialog = () => {
			emit('update:active', false);
		};
		return {
			closeDialog,
		};
	},
	components: { TradistIcon, HollowXIcon },
});
