
import { defineComponent } from 'vue';
import LightThemeIcon from './Icons/LightThemeIcon.vue';
import DarkThemeIcon from './Icons/DarkThemeIcon.vue';
import { useTheme } from '../composables/useTheme';
import { config } from '../config';

export default defineComponent({
	components: { LightThemeIcon, DarkThemeIcon },
	props: {
		darkThemeClass: {
			type: String,
			default: '',
		},
		lightThemeClass: {
			type: String,
			default: '',
		},
	},
	setup() {
		const { isDarkTheme, toggleTheme } = useTheme();
		return {
			config,
			isDarkTheme,
			toggleTheme,
		};
	},
});
