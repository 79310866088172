
import BaseButton from '../Base/BaseButton.vue';
import NewsletterArrowIcon from '../Icons/NewsletterArrowIcon.vue';

export default {
	components: {
		BaseButton,
		NewsletterArrowIcon,
	},
};
