<template>
	<svg
		width="40px"
		height="40px"
		viewBox="0 0 40 40"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-105.000000, -914.000000)">
				<g transform="translate(85.000000, 869.000000)">
					<g transform="translate(20.000000, 45.000000)">
						<g>
							<polygon points="0 0 40 0 40 40 0 40"></polygon>
						</g>
						<path
							d="M24.4525503,9.87144829 L27.7999836,6.52213618 L27.7999836,6.52213618 C29.82836,4.49262127 33.1170079,4.49262127 35.1453844,6.52213618 C37.1737608,8.5516511 37.1737608,11.8421449 35.1453844,13.8716598 L31.7790797,17.2398539 C31.3834053,17.6357504 30.7417104,17.6359304 30.3458139,17.2402561 C30.3448847,17.2393274 30.3439573,17.2383969 30.3430317,17.2374646 L29.3308083,16.2179099 C28.9375375,15.8217908 28.9386016,15.1822439 29.3331884,14.7874356 L32.6969174,11.4218186 L32.6969174,11.4218186 C33.3730429,10.7453136 33.3730429,9.64848237 32.6969174,8.9719774 C32.020792,8.29547243 30.924576,8.29547243 30.2484505,8.9719774 L26.8984416,12.3238666 C26.5027672,12.7197631 25.8610723,12.7199431 25.4651758,12.3242687 C25.4642466,12.32334 25.4633192,12.3224095 25.4623936,12.3214772 L24.4501702,11.3019226 C24.0568994,10.9058034 24.0579635,10.2662565 24.4525503,9.87144829 Z"
							class="c2-secondary"
							fill="#777E91"
						></path>
						<path
							d="M22.7919569,12.9666419 L22.7947392,12.9694334 L23.8069625,13.9889881 C24.2002333,14.3851072 24.1991692,15.0246541 23.8045824,15.4194623 L16.7818824,22.4461041 L19.2303493,24.8959453 L26.2393292,17.8830314 C26.6350036,17.4871349 27.2766985,17.4869549 27.672595,17.8826292 C27.6735242,17.8835579 27.6744516,17.8844885 27.6753772,17.8854208 L28.6876006,18.9049754 C29.0808714,19.3010945 29.0798073,19.9406414 28.6852205,20.3354497 L20.6135107,28.41169 C20.3762089,30.8409694 18.877272,33.0338247 16.5820088,33.9253577 C13.354513,35.1789914 9.37661203,35.329352 4.65185258,34.4102218 C3.77912578,34.2409165 3.20059136,33.3484238 3.35964041,32.4172902 C3.38650045,32.28107 3.38650045,32.28107 3.42699233,32.1487435 C5.24207127,26.7201206 8.22531383,23.1460956 12.3581702,21.540799 C12.5700487,21.4585021 12.7834078,21.3893543 12.9974629,21.3329653 L21.3586911,12.967044 C21.7543655,12.5711476 22.3960605,12.5709675 22.7919569,12.9666419 Z M13.6112792,24.7705666 C10.9777877,25.7934753 8.90657849,27.9552829 7.43078055,31.349003 C10.6090449,31.7427251 13.2343684,31.5091543 15.3288999,30.6955901 C16.7681797,30.1365407 17.5735747,28.2933279 16.9954414,26.598205 C16.6722462,25.6505765 15.9933041,24.9785652 15.1857946,24.7196031 C14.6710674,24.554534 14.1329375,24.5679462 13.6112792,24.7705666 Z"
							class="c1-primary"
							fill="#CAFF15"
						></path>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
