
import { computed, defineComponent, nextTick, ref, watchEffect } from 'vue';
import { useDropdown } from '../../composables/useDropdown';
import ChevronDownIcon from '../Icons/ChevronDownIcon.vue';
import BaseInput from './BaseInput.vue';
import BaseSelectItem from './BaseSelectItem.vue';

export default defineComponent({
	components: { ChevronDownIcon, BaseInput, BaseSelectItem },
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		displayKey: {
			type: String,
			// required: true,
		},
		defaultValue: {
			type: String,
			default: '-',
		},
		text: {
			type: String,
			default: '',
		},
		selectedItems: {
			type: [String, Number, Object, Array],
			default: 0,
		},
		multiple: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['searchSelect'],
	setup(props, { emit }) {
		const { isDropdownOpen, closeDropdown, openDropdown } = useDropdown('');

		const displayValue = computed(() => {
			return props.text
				? props.text
				: props.selectedItems || props.defaultValue;
		});

		const filterInputValue = ref('');

		const fitleredData = computed(() => {
			const lowerCasedFilter = filterInputValue.value.toLowerCase();
			return props.data.filter((item) => {
				const lowerCasedItem = item[props.displayKey].toLowerCase();
				return lowerCasedItem.includes(lowerCasedFilter);
			});
		});

		const handleSearchInputEnterKey = () => {
			closeDropdown();
			emit('searchSelect', filterInputValue.value);
			filterInputValue.value = '';
		};

		const searchInput = ref(undefined);

		const openDropdownInputFocus = () => {
			openDropdown();
			nextTick(() => {
				searchInput.value.$el.focus();
			});
		};

		const closeDropdownOnEsc = (event) => {
			if (event.key === 'Escape') {
				closeDropdown();
				filterInputValue.value = '';
			}
		};

		watchEffect(() => {
			if (isDropdownOpen.value) {
				document.addEventListener('keyup', closeDropdownOnEsc);
			} else {
				document.removeEventListener('keyup', closeDropdownOnEsc);
			}
		});

		return {
			isDropdownOpen,
			closeDropdown,
			openDropdown,
			displayValue,
			filterInputValue,
			fitleredData,
			handleSearchInputEnterKey,
			searchInput,
			openDropdownInputFocus,
		};
	},
});
