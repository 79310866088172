
import { computed, defineComponent, ref } from 'vue';
import BaseButton from '../Base/BaseButton.vue';
import BaseToaster from '../Base/BaseToaster.vue';
import LoginRegisterDialog from '../Dialogs/LoginRegisterDialog.vue';
import { config } from '../../config';
import { logInWithMetaMask } from '../../composables/useMetamask';
import { useModal } from '../../composables/useModal';

export default defineComponent({
	components: { BaseButton, LoginRegisterDialog, BaseToaster },
	setup() {
		const { isLoginRegisterModalOpen, openLoginRegisterModal } =
			useModal('LoginRegister');

		const toaster = ref({
			visible: false,
			text: '',
			buttonText: 'Close',
			type: 'danger',
			action: () => (toaster.value.visible = false),
		});

		const handleLogin = async () => {
			if (loginButtonText.value) {
				try {
					await logInWithMetaMask();
				} catch (error: unknown) {
					if (typeof error === 'string') {
						toaster.value.text = error as string;
						toaster.value.visible = true;
					}
					console.log(error);
				}
			} else {
				openLoginRegisterModal();
			}
		};

		const loginButtonText = computed(() => {
			return config.LOGIN_WITH_METAMASK_ONLY ? 'Login With Metamask' : 'Login';
		});

		return {
			config,
			isLoginRegisterModalOpen,
			toaster,
			handleLogin,
			loginButtonText,
		};
	},
});
