
import { defineComponent } from 'vue';
import BaseDivider from './Base/BaseDivider.vue';
import BaseInput from './Base/BaseInput.vue';
import BaseSelect from './Base/BaseSelect.vue';
import BaseSelectItem from './Base/BaseSelectItem.vue';
import TagButton from './Buttons/TagButton.vue';

export default defineComponent({
	setup() {
		return {};
	},
	components: { BaseDivider, BaseSelect, BaseSelectItem, TagButton, BaseInput },
});
