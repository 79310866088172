import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01b1a087"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner" }
const _hoisted_2 = { class: "banner__text" }
const _hoisted_3 = { class: "banner__text--primary" }
const _hoisted_4 = { class: "banner__text--secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.primaryText), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.secondaryText), 1)
    ])
  ]))
}