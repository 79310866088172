
import { defineComponent } from 'vue';
import BaseButton from './BaseButton.vue';

export default defineComponent({
	components: { BaseButton },
	props: {
		active: {
			type: Boolean,
			required: true,
		},
		text: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			validator(val: string) {
				return ['primary', 'success', 'warning', 'danger'].includes(val);
			},
		},
	},
	emits: ['click'],
	setup() {
		return {};
	},
});
