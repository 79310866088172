import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useUser } from '../composables/useUser';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/deneme',
		name: 'deneme',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "deneme" */ '../views/Deneme.vue'),
	},
	{
		path: '/create',
		name: 'create',
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/Create.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/my-items',
		name: 'my-items',
		component: () =>
			import(/* webpackChunkName: "my-items" */ '../views/MyItems.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/user/:address/items',
		name: 'items',
		component: () =>
			import(/* webpackChunkName: "my-items" */ '../views/MyItems.vue'),
		meta: {
			authRequired: true,
		},
	},
	{
		path: '/token/:contractAddress/:tokenId',
		name: 'token',
		component: () =>
			import(/* webpackChunkName: "token" */ '../views/Token.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

const { isUserLoggedIn } = useUser();

router.beforeEach(async (to) => {
	if (to.meta.authRequired) {
		return isUserLoggedIn.value ? true : '/';
	}
	return true;
});

export default router;
