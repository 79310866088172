
import { defineComponent, onMounted } from 'vue';
import TheFooter from './components/Layout/TheFooter.vue';
import TheNavbar from './components/Layout/TheNavbar.vue';
import { useLoader } from './composables/useLoader';
import { initMetamaskConnection } from './composables/useMetamask';

export default defineComponent({
	components: { TheFooter, TheNavbar },
	setup() {
		const { loader, openLoader, closeLoader } = useLoader();

		onMounted(async () => {
			try {
				openLoader();
				await initMetamaskConnection();
			} catch (error: unknown) {
				console.log(error);
			} finally {
				closeLoader();
			}
		});

		return {
			loader,
		};
	},
});
