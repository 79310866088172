export const config = {
	LOGIN_WITH_METAMASK_ONLY: true,
	HOME_PAGE_HOT_BID_FILTER_SECTION: false,
	POPULAR_DATE_LIMIT_FILTER: false,
	AUCTION_ENABLED: false,
	SEARCH_ENABLED: false,
	THEME_SWITCH_ENABLED: false,
	BURGER_MENU_ENABLED: false,
	BANNER_STATS_ENABLED: false,
	USER_AVATAR_ENABLED: false,
	SALE_QUANTITY_ENABLED: false,
	POPULAR_SELLER_ENABLED: false,
	LIST_NFT_IMMEDIATELY: false,
};
