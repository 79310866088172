import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    class: "button--tag",
    isText: "",
    size: "none",
    bgColor: "transparent",
    fontSize: "sm",
    onClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["onClick"]))
}