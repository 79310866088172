<template>
	<svg
		width="18px"
		height="15px"
		viewBox="0 0 18 15"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g
				transform="translate(-1321.000000, -1917.000000)"
				fill="#353945"
				fill-rule="nonzero"
			>
				<g transform="translate(1321.777570, 1917.666000)">
					<polygon
						points="16.3333575 5.83330316 4.66667874 5.83330316 8.75002716 1.74999095 7.00003621 0 0 7 7 14 8.74999095 12.2500091 4.66667874 8.16666063 16.3333575 8.16666063"
					></polygon>
				</g>
			</g>
		</g>
	</svg>
</template>
