
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useUser } from '../../composables/useUser';
import { config } from '../../config';
import { weiToEther } from '../../utils';

export default defineComponent({
	name: 'BaseCard',
	props: {
		assetUrl: {
			type: String,
			default: '',
		},
		assetType: {
			type: String,
			default: 'image',
		},
		sellerAvatarUrl: {
			type: String,
			default: '',
		},
		sellerName: {
			type: String,
			default: '',
		},
		sellerAddress: {
			type: String,
			default: '',
		},
		sellerID: {
			type: Number,
			default: 0,
		},
		newBid: {
			type: Boolean,
			default: true,
		},
		biddingTime: {
			type: Date,
			default: () => new Date(),
		},
		sellerBadge: {
			type: String,
			default: '',
		},
		assetPrice: {
			type: [Number, String],
			default: 0,
		},
		assetPriceCurrency: {
			type: String,
			default: 'ETH',
		},
		assetQuantity: {
			type: Number,
			default: 0,
		},
		assetTitle: {
			type: String,
			default: '',
		},
		assetDescription: {
			type: String,
			default: '',
		},
		assetTags: {
			type: Array,
			default: () => [],
		},
		priceShadowed: {
			type: Boolean,
			default: false,
		},
		fixedHeight: {
			type: Boolean,
			default: false,
		},
		isWide: {
			type: Boolean,
			default: false,
		},
		tokenId: {
			type: String,
			default: '',
		},
		contractAddress: {
			type: String,
			default: '',
		},
	},
	setup(props) {
		const shorthenedSellerAddress = (address: string) => {
			const charLimit = 4;
			const hexOffset = 2; // 0x
			return `${address.substring(
				0,
				charLimit + hexOffset,
			)}...${address.substring(address.length - charLimit)}`;
		};

		const { user } = useUser();
		const route = useRouter();

		const redirectToUser = () => {
			if (user.value.address === props.sellerAddress) {
				route.push('/my-items');
			} else {
				route.push(`/user/${props.sellerAddress}/items`);
			}
		};

		return {
			config,
			shorthenedSellerAddress,
			redirectToUser,
			weiToEther,
		};
	},
});
