import { ref, Ref } from 'vue';

type Keys<T extends string> =
	| `is${T}ModalOpen`
	| `open${T}Modal`
	| `close${T}Modal`;

type DetermineType<T extends string> = T extends `is${string}ModalOpen`
	? Ref<boolean>
	: () => void;

type UseModalReturn<T extends string> = {
	[A in Keys<T>]: DetermineType<A>;
};

export function useModal<K extends string>(prefix: K): UseModalReturn<K> {
	const isModalOpen = ref(false);

	const openModal = () => {
		isModalOpen.value = true;
	};

	const closeModal = () => {
		isModalOpen.value = false;
	};

	return {
		[`is${prefix}ModalOpen`]: isModalOpen,
		[`open${prefix}Modal`]: openModal,
		[`close${prefix}Modal`]: closeModal,
	} as UseModalReturn<K>;
}
