
import { defineComponent } from 'vue';
import BaseDivider from '../Base/BaseDivider.vue';

export default defineComponent({
	components: { BaseDivider },
	setup() {
		const menuItems = [
			{ title: 'Help Center', link: '/' },
			{ title: 'Newsletter', link: '/' },
			{ title: 'Blog', link: '/' },
			{ title: 'Partners', link: '/' },
		];

		return {
			menuItems,
		};
	},
	emits: ['closeDropdown'],
});
