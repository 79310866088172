import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftArrowIcon = _resolveComponent("LeftArrowIcon")!
  const _component_RightArrowIcon = _resolveComponent("RightArrowIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "before"),
    _createVNode(_component_LeftArrowIcon, {
      class: "inline-block cursor-pointer",
      onClick: _ctx.previousPage
    }, null, 8, ["onClick"]),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_RightArrowIcon, {
      class: "inline-block cursor-pointer",
      onClick: _ctx.nextPage
    }, null, 8, ["onClick"]),
    _renderSlot(_ctx.$slots, "after")
  ]))
}