<template>
	<svg
		width="66px"
		height="59px"
		viewBox="0 0 66 59"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g
				transform="translate(-85.000000, -594.000000)"
				class="c1-primary"
				fill="#CAFF15"
				fill-rule="nonzero"
			>
				<g transform="translate(81.000000, 158.000000)">
					<g transform="translate(4.000000, 436.000000)">
						<polygon
							transform="translate(18.944249, 30.666000) scale(-1, 1) translate(-18.944249, -30.666000) "
							points="27.1109276 29.4993032 15.4442489 29.4993032 19.5275973 25.4159909 17.7776063 23.666 10.7775701 30.666 17.7775701 37.666 19.5275611 35.9160091 15.4442489 31.8326606 27.1109276 31.8326606"
						></polygon>
						<polygon
							points="66 0 0 0 0 59 66 59 66 46.5737194 64.6658312 46.5737194 64.665 57.7065385 1.333 57.7065385 1.333 1.29255385 64.665 1.29255385 64.6658312 10.8082565 66 10.8082565"
						></polygon>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
