
import { computed, defineComponent } from 'vue';
import BaseButton from '../Base/BaseButton.vue';
import XIcon from '../Icons/XIcon.vue';

export default defineComponent({
	components: { BaseButton, XIcon },
	props: {
		active: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const textColor = computed(() => {
			return props.active ? 'primary' : 'theme';
		});

		return {
			textColor,
		};
	},
});
