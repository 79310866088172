
import { defineComponent, ref, toRaw } from 'vue';
import { useLoader } from '../../composables/useLoader';
import { apiService } from '../../services/ApiService';
import { ClickFunction } from '../../types/functions';
import BaseCheckbox from '../Base/BaseCheckbox.vue';
import LinkAndButtonGroup from '../Groups/LinkAndButtonGroup.vue';
import MaterialInputWithLabel from '../Groups/MaterialInputWithLabel.vue';

export default defineComponent({
	components: { MaterialInputWithLabel, LinkAndButtonGroup, BaseCheckbox },
	props: {
		loginLinkClick: {
			type: ClickFunction,
			required: true,
		},
	},
	setup() {
		const registerState = ref({
			fullName: '',
			email: '',
			username: '',
			password: '',
			confirmPassword: '',
			aggreements: false,
		});

		const emptyErrorState = {
			alert: '',
			fullName: '',
			email: '',
			username: '',
			password: '',
			confirmPassword: '',
			aggreements: false,
		};

		const errorState = ref({ ...emptyErrorState });

		const { openLoader, closeLoader } = useLoader();

		const handleFormSubmit = async () => {
			errorState.value = { ...emptyErrorState };
			try {
				const doesPasswordsNotMatch =
					registerState.value.password !== registerState.value.confirmPassword;
				if (doesPasswordsNotMatch) {
					errorState.value.confirmPassword = 'Passwords must match eachother';
					return;
				}
				if (!registerState.value.aggreements) {
					errorState.value.alert =
						'You must check aggrements to proceed with registration.';
					errorState.value.aggreements = true;
					return;
				}
				openLoader();
				const { accessToken } = await apiService.registerTraditional(
					toRaw(registerState.value),
				);
				apiService.setToken(accessToken);
			} finally {
				closeLoader();
			}
		};

		return {
			registerState,
			errorState,
			handleFormSubmit,
		};
	},
});
