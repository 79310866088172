
import { defineComponent } from 'vue';
import { useUser } from '../../composables/useUser';
import TradistIcon from '../Icons/TradistIcon.vue';
import BaseDivider from '../Base/BaseDivider.vue';

export default defineComponent({
	components: { TradistIcon, BaseDivider },
	setup() {
		const { user } = useUser();

		const menuItems = [
			{ title: 'Member', link: '/' },
			{ title: 'Edit Bio', link: '/' },
			{ title: 'Sign Out', link: '/' },
		];

		return {
			user,
			menuItems,
		};
	},
	emits: ['closeDropdown'],
});
