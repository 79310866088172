
import { defineComponent } from 'vue';
import BaseButton from '../Base/BaseButton.vue';

export default defineComponent({
	props: {
		active: {
			type: Boolean,
			default: true,
		},
		text: {
			type: String,
			default: '',
		},
	},
	emits: ['click'],
	setup(props, { emit }) {
		const handleClick = (event) => {
			emit('click', event);
		};
		return {
			handleClick,
		};
	},
	components: { BaseButton },
});
