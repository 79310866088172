<template>
	<svg
		width="14px"
		height="9px"
		viewBox="0 0 14 9"
		version="1.1"
		fill="#26272D"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="inherit" fill-rule="evenodd">
			<g
				transform="translate(-1334.000000, -1266.000000)"
				fill="inherit"
				fill-rule="nonzero"
			>
				<g transform="translate(1109.000000, 1245.000000)">
					<g transform="translate(225.000000, 21.000000)">
						<polygon
							points="12.3491484 0 7 5.56507529 1.65085156 0 0 1.71743391 7 9 14 1.71743391"
						></polygon>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
