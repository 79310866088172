
import { defineComponent, ref, toRaw } from 'vue';
import { useLoader } from '../../composables/useLoader';
import { logInWithMetaMask } from '../../composables/useMetamask';
import { apiService } from '../../services/ApiService';
import { ClickFunction } from '../../types/functions';
import BaseButton from '../Base/BaseButton.vue';
import MaterialInputWithLabel from '../Groups/MaterialInputWithLabel.vue';

export default defineComponent({
	components: { MaterialInputWithLabel, BaseButton },
	props: {
		forgotPasswordLinkClick: {
			type: ClickFunction,
			required: true,
		},
		registerLinkClick: {
			type: ClickFunction,
			required: true,
		},
	},
	setup() {
		const loginState = ref({
			email: '',
			password: '',
		});

		const loginError = ref({
			isErrored: false,
			message: '',
		});

		const { openLoader, closeLoader } = useLoader();

		const handleMetaMaskConnection = async () => {
			openLoader('Connecting Metamask');
			await logInWithMetaMask();
			closeLoader();
		};

		const handleFormSubmit = async () => {
			loginError.value.isErrored = false;
			loginError.value.message = '';
			try {
				await apiService.loginTraditional(toRaw(loginState.value));
			} catch (error) {
				loginError.value.isErrored = true;
				loginError.value.message = error.response?.data.message;
			}
		};

		return {
			loginState,
			loginError,
			handleMetaMaskConnection,
			handleFormSubmit,
		};
	},
});
