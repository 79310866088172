import { BigNumber, ethers, providers, Signer } from 'ethers';

declare global {
	interface Window {
		ethereum: unknown;
	}
}
class CryptoWallet {
	static instance: CryptoWallet;
	address: string;
	provider: providers.Web3Provider;
	signer: Signer;
	balance: BigNumber;

	chainId: number;
	constructor() {
		if (window.ethereum) {
			this.provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
			this.provider.on('network', (newNetwork) => {
				if (newNetwork === 4) {
					this.provider = new ethers.providers.Web3Provider(
						window.ethereum,
						'any',
					);
				}
			});
		}
	}

	static getInstance() {
		if (!this.instance) {
			this.instance = new CryptoWallet();
		}
		return this.instance;
	}
	async connect() {
		await this.provider.send('eth_requestAccounts', []);
	}

	async initializeUserWallet() {
		this.chainId = (await this.provider.getNetwork()).chainId;
		if (this.chainId !== 4) {
			throw 'Please choose network as Rinkeby Test Network from Metamask to proceed.';
		}
		this.signer = this.provider.getSigner();
		this.address = await this.signer.getAddress();
		await this.updateBalance();
	}

	async updateBalance() {
		this.balance = await this.signer.getBalance();
		return this.balance;
	}

	async registerSign(): Promise<{ showMessage: string; signature?: string }> {
		const showMessage = 'Verify That You Own This Account!';
		this.signer = this.provider.getSigner();
		const signature = await this.signer.signMessage(showMessage);
		return {
			signature,
			showMessage,
		};
	}
}

export const cryptoWallet = CryptoWallet.getInstance();
