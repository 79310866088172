<template>
	<svg
		width="17px"
		height="17px"
		viewBox="0 0 17 17"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		class="dark-theme-default-fill"
	>
		<g stroke="none" stroke-width="1" fill-rule="evenodd">
			<g transform="translate(-1259.000000, -84.000000)" fill-rule="nonzero">
				<path
					d="M1272.78835,96.9249463 C1267.42657,96.9187041 1263.08129,92.5736701 1263.07505,87.2116533 C1263.07272,86.1179287 1263.25717,85.0316702 1263.62032,84 C1260.27501,85.8884375 1258.47861,89.6765728 1259.13343,93.4618929 C1259.78849,97.2470908 1262.7529,100.21175 1266.53823,100.866565 C1270.32343,101.52138 1274.11156,99.7251065 1276,96.3796751 C1274.96833,96.7430668 1273.88232,96.9273942 1272.78835,96.9249463 Z"
				></path>
			</g>
		</g>
	</svg>
</template>
