import { cryptoWallet } from '@tradist/blockchain/frontend';
import { BigNumber, ethers } from 'ethers';
import { apiService } from '../services/ApiService';
import { useUser } from './useUser';

const { user } = useUser();

const checkMetamaskInstalled = () => {
	if (window.ethereum === undefined) {
		throw 'Metamask is not installed, please install Metamask extention first.';
	}
};

const etherFormatter = (balance: BigNumber): string => {
	return ethers.utils.formatEther(balance).substring(0, 7);
};

const mapWalletDataToUser = () => {
	user.value.address = cryptoWallet.address;
	user.value.balance = etherFormatter(cryptoWallet.balance);
	user.value.currency = 'ETH';
	user.value.avatar =
		'https://images.unsplash.com/photo-1615048907322-fc7617b95190?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80';
};

const loginWallet = async (): Promise<boolean> => {
	try {
		const { data } = await apiService.loginWallet({
			address: cryptoWallet.address,
			chainId: cryptoWallet.chainId,
		});
		apiService.setToken(data.access_token);
		return true;
	} catch (error) {
		console.log(error.response?.data.message);
		return false;
	}
};

const registerWallet = async () => {
	const { showMessage } = await cryptoWallet.registerSign();
	const { data } = await apiService.registerWallet({
		address: cryptoWallet.address,
		chainId: cryptoWallet.chainId,
		signedMessage: showMessage,
	});
	apiService.setToken(data.access_token);
};

export async function initMetamaskConnection(): Promise<void> {
	checkMetamaskInstalled();
	await cryptoWallet.initializeUserWallet();
	const isWalletLoggedIn = await loginWallet();
	if (isWalletLoggedIn) {
		mapWalletDataToUser();
	}
}

export async function logInWithMetaMask(): Promise<void> {
	checkMetamaskInstalled();
	await cryptoWallet.connect();
	await cryptoWallet.initializeUserWallet();
	const isWalletLoggedIn = await loginWallet();
	if (!isWalletLoggedIn) {
		await registerWallet();
	}
	mapWalletDataToUser();
}
