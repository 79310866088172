
import { defineComponent, ref } from 'vue';
import MaterialInputWithLabel from '../Groups/MaterialInputWithLabel.vue';
import LinkAndButtonGroup from '../Groups/LinkAndButtonGroup.vue';
import { ClickFunction } from '../../types/functions';

export default defineComponent({
	components: { MaterialInputWithLabel, LinkAndButtonGroup },
	props: {
		loginLinkClick: {
			type: ClickFunction,
			required: true,
		},
		openNewPasswordForm: {
			type: Function,
			required: true,
		},
	},
	setup(props) {
		const email = ref('');
		const error = ref(false);
		const errorMessage = ref('');

		const handleSend = () => {
			props.openNewPasswordForm();
		};

		return {
			email,
			error,
			errorMessage,
			handleSend,
		};
	},
});
