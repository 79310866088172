<template>
	<svg
		width="16px"
		height="16px"
		viewBox="0 0 16 16"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-901.000000, -52.000000)" fill="#FFFFFF">
				<g transform="translate(901.000000, 36.000000)">
					<path
						d="M7,28 C4.243,28 2,25.757 2,23 C2,20.243 4.243,18 7,18 C9.757,18 12,20.243 12,23 C12,25.757 9.757,28 7,28 Z M15.7067546,30.293 L12.5948032,27.201 C12.5928032,27.199 12.5908033,27.199 12.5888033,27.197 C13.4707895,26.025 13.9997813,24.575 13.9997813,23 C13.9997813,19.141 10.8588303,16 6.99989063,16 C3.13995094,16 0,19.141 0,23 C0,26.859 3.13995094,30 6.99989063,30 C8.57486602,30 10.0248434,29.471 11.1958251,28.59 C11.197825,28.592 11.197825,28.594 11.199825,28.596 L14.2927767,31.707 C14.4877736,31.902 14.7437696,32 14.9997656,32 C15.2557616,32 15.5117576,31.902 15.7067546,31.707 C16.0977485,31.316 16.0977485,30.684 15.7067546,30.293 L15.7067546,30.293 Z"
					></path>
				</g>
			</g>
		</g>
	</svg>
</template>
