<template>
	<svg
		width="7px"
		height="14px"
		viewBox="0 0 7 14"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-1231.000000, -352.000000)" fill="#4B4D5C">
				<path
					d="M1235.63418,365.218935 L1235.63418,358.608694 L1237.42898,358.608694 L1237.66683,356.330768 L1235.63418,356.330768 L1235.63723,355.190644 C1235.63723,354.596526 1235.69275,354.278184 1236.53209,354.278184 L1237.65412,354.278184 L1237.65412,352 L1235.85907,352 C1233.70291,352 1232.94401,353.105039 1232.94401,354.963367 L1232.94401,356.331026 L1231.6,356.331026 L1231.6,358.608952 L1232.94401,358.608952 L1232.94401,365.218935 L1235.63418,365.218935 Z"
				></path>
			</g>
		</g>
	</svg>
</template>
