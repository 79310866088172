
import { defineComponent } from 'vue';
import { config } from '../../config';
import BaseButton from '../Base/BaseButton.vue';
import SearchIcon from '../Icons/SearchIcon.vue';

export default defineComponent({
	components: { SearchIcon, BaseButton },
	setup() {
		return {
			config,
		};
	},
});
