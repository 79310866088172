import { ethers } from 'ethers';

export function shorthenedSellerAddress(address: string) {
	const charLimit = 4;
	const hexOffset = 2; // 0x
	return `${address.substring(0, charLimit + hexOffset)}...${address.substring(
		address.length - charLimit,
	)}`;
}

export function weiToEther(wei: string | number) {
	return ethers.utils.formatEther(wei).substring(0, 7);
}
