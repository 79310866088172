import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b34cd44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog page-background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HollowXIcon = _resolveComponent("HollowXIcon")!
  const _component_TradistIcon = _resolveComponent("TradistIcon")!

  return (_ctx.active)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#dialog-target"
      }, [
        _createElementVNode("div", {
          class: "dialog--background",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)))
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_HollowXIcon, {
            class: "dialog--x-icon",
            onClick: _ctx.closeDialog
          }, null, 8, ["onClick"]),
          _createVNode(_component_TradistIcon, {
            class: "dialog--logo",
            themeResponsive: ""
          }),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]))
    : _createCommentVNode("", true)
}