
import { computed, defineComponent, ref } from 'vue';
import BaseBanner from '../components/Base/BaseBanner.vue';
import BaseCard from '../components/Base/BaseCard.vue';
import BrushIcon from '../components/Icons/BrushIcon.vue';
import PriceUpIcon from '../components/Icons/PriceUpIcon.vue';
import TransactionIcon from '../components/Icons/TransactionIcon.vue';
import LineThroughSideHeader from '../components/LineThroughHeaders/LineThroughSideHeader.vue';
import BaseSelect from '../components/Base/BaseSelect.vue';
import BaseSelectItem from '../components/Base/BaseSelectItem.vue';
import LineThroughCenterHeader from '../components/LineThroughHeaders/LineThroughCenterHeader.vue';
import LineThroughFirstHeader from '../components/LineThroughHeaders/LineThroughFirstHeader.vue';
import LineThroughSecondHeader from '../components/LineThroughHeaders/LineThroughSecondHeader.vue';
import BasePaginator from '../components/Base/BasePaginator.vue';
import FilterButton from '../components/Buttons/FilterButton.vue';
import FilterSection from '../components/FilterSection.vue';
import { config } from '../config';

import MasonryWall from '@yeger/vue-masonry-wall';
import TheHero from '../components/TheHero.vue';
import BaseDivider from '../components/Base/BaseDivider.vue';
import { apiService } from '../services/ApiService';
import BaseButton from '../components/Base/BaseButton.vue';

export default defineComponent({
	name: 'Home',
	components: {
		BaseCard,
		BaseBanner,
		LineThroughSideHeader,
		BaseSelect,
		BaseSelectItem,
		LineThroughCenterHeader,
		LineThroughFirstHeader,
		LineThroughSecondHeader,
		BasePaginator,
		FilterButton,
		FilterSection,
		MasonryWall,
		TheHero,
		BaseDivider,
		BaseButton,
	},
	setup() {
		const bannerData = [
			{ primaryText: '75,088', secondaryText: 'NFTS CREATED', icon: BrushIcon },
			{
				primaryText: '6,736',
				secondaryText: 'TRANSACTIONS',
				icon: TransactionIcon,
			},
			{
				primaryText: '$78,428.76',
				secondaryText: 'TOTAL SALES',
				icon: PriceUpIcon,
			},
		];

		const pagination = {
			page: 0,
			pageSize: 12,
			count: 0,
		};

		const nftItems = ref([]);

		const getAllNfts = async () => {
			const { data } = (await apiService.getAllNfts(pagination)) as {
				data: { tokens: []; count: number };
			};
			pagination.count = data.count;
			nftItems.value = [...nftItems.value, ...data.tokens];
		};

		getAllNfts();

		const handleMoreButton = () => {
			pagination.page++;
			getAllNfts();
		};

		const hasMorePages = computed(() => {
			return pagination.count > nftItems.value.length;
		});

		return {
			config,
			bannerData,
			nftItems,
			handleMoreButton,
			hasMorePages,
		};
	},
});
