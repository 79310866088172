
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		primaryText: String,
		secondaryText: String,
	},
	setup() {
		return {};
	},
});
