
import { defineComponent, ref } from 'vue';
import BaseButton from '../Base/BaseButton.vue';
import MaterialInputWithLabel from '../Groups/MaterialInputWithLabel.vue';

export default defineComponent({
	components: { MaterialInputWithLabel, BaseButton },
	setup() {
		const newPasswordState = ref({
			password: '',
			confirmPassword: '',
		});

		const errorState = ref({
			password: '',
			confirmPassword: '',
		});
		return {
			newPasswordState,
			errorState,
		};
	},
});
