<template>
	<svg
		width="42px"
		height="42px"
		viewBox="0 0 42 42"
		version="1.1"
		fill="#00000000"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="Page-1"
			stroke="none"
			stroke-width="1"
			fill="inherit"
			fill-rule="evenodd"
			stroke-linecap="square"
		>
			<g
				class="stroke-primary"
				id="Login"
				fill="inherit"
				transform="translate(-987.000000, -269.000000)"
				fill-rule="nonzero"
			>
				<g transform="translate(370.000000, 231.000000)">
					<polygon
						points="658 45.6291277 651.370872 39 638.000039 52.3708333 624.629128 39 618 45.6291277 625.348225 52.9773093 631.370911 58.9999609 618 72.3708723 624.629128 79 638.000039 65.6291667 651.370872 79 658 72.3708723 644.629089 58.9999609"
					></polygon>
				</g>
			</g>
		</g>
	</svg>
</template>
