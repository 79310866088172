<template>
	<svg
		width="10px"
		height="10px"
		viewBox="0 0 10 10"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-1335.000000, -2002.000000)" class="c1-primary">
				<path
					d="M1336.47206,2002.52851 L1340.00065,2006.05711 L1343.52925,2002.52851 C1343.7896,2002.26816 1344.21171,2002.26816 1344.47206,2002.52851 C1344.73241,2002.78886 1344.73241,2003.21097 1344.47206,2003.47132 L1340.94346,2006.99992 L1344.47206,2010.52851 C1344.73241,2010.78886 1344.73241,2011.21097 1344.47206,2011.47132 C1344.21171,2011.73167 1343.7896,2011.73167 1343.52925,2011.47132 L1340.00065,2007.94273 L1336.47206,2011.47132 C1336.21171,2011.73167 1335.7896,2011.73167 1335.52925,2011.47132 C1335.2689,2011.21097 1335.2689,2010.78886 1335.52925,2010.52851 L1339.05784,2006.99992 L1335.52925,2003.47132 C1335.2689,2003.21097 1335.2689,2002.78886 1335.52925,2002.52851 C1335.7896,2002.26816 1336.21171,2002.26816 1336.47206,2002.52851 Z"
				></path>
			</g>
		</g>
	</svg>
</template>
