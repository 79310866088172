import { cryptoWallet } from '@tradist/blockchain/frontend';
import { computed, ComputedRef, ref, Ref } from 'vue';
import { weiToEther } from '../utils';

interface IUser {
	id?: number;
	username?: string;
	currency?: string;
	avatar?: string;
	address?: string;

	balance?: string;
}

const user = ref<IUser>({});

const isUserLoggedIn = computed(() => {
	const doesUserLoggedInWithServer = user.value.id >= 0;
	const doesUserLoggedInWithMetamask = !!user.value.address;

	return doesUserLoggedInWithServer || doesUserLoggedInWithMetamask;
});

interface IUseUserReturn {
	user: Ref<IUser>;
	isUserLoggedIn: ComputedRef<boolean>;
	updateBalance: () => void;
}

const updateBalance = async () => {
	user.value.balance = weiToEther(`${await cryptoWallet.updateBalance()}`);
};

export function useUser(): IUseUserReturn {
	return {
		user,
		isUserLoggedIn,
		updateBalance,
	};
}
