import axios, { AxiosStatic } from 'axios';
import { INFTMetadata } from '../types/NFTMetadata';

class ApiService {
	static instance: ApiService;
	public axios: AxiosStatic;
	constructor() {
		this.axios = axios;
		this.axios.defaults.baseURL = 'https://test-api.tradist.io/api';
	}

	setToken(token) {
		this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}

	static getInstance() {
		if (!this.instance) {
			this.instance = new ApiService();
		}
		return this.instance;
	}

	initializeNFTMetadata(metadata: INFTMetadata) {
		const formData = new FormData();
		const { assetFile, ...rest } = metadata;
		formData.append('data', JSON.stringify(rest));
		formData.append('assetFile', assetFile);
		return this.axios.post('/erc721/metadata', formData);
	}

	getContracts() {
		return this.axios.get('/chain/contracts');
	}

	loginWallet({ address, chainId }) {
		return this.axios.post('/auth/login-wallet', {
			address,
			chainId,
		});
	}

	registerWallet({ address, chainId, signedMessage }) {
		return this.axios.post('/auth/register-wallet', {
			address,
			chainId,
			signedMessage,
		});
	}

	loginTraditional({ email, password }) {
		return this.axios.post('/auth/login', {
			email,
			password,
		});
	}

	registerTraditional({ email, password }) {
		return this.axios.post('/auth/register', {
			email,
			password,
		}) as Promise<{ accessToken: string }>;
	}

	getMyItems(page) {
		return this.axios.get('user-profile/items', {
			params: {
				page: page,
			},
		});
	}

	getUserItems(address, page) {
		return this.axios.get(`user-profile/${address}/items`, {
			params: {
				page: page,
			},
		});
	}

	getAllNfts({ page, pageSize }) {
		return this.axios.get('/erc721/items', {
			params: {
				page,
				pageSize,
			},
		});
	}

	getHeroes() {
		return this.axios.get('/heroes');
	}

	getTokenDetail(contractAddress, tokenId) {
		return this.axios.get(`/erc721/detail/${contractAddress}/${tokenId}`);
	}
}

export const apiService = ApiService.getInstance();
