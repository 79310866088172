<template>
	<svg
		width="14px"
		height="13px"
		viewBox="0 0 14 13"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g transform="translate(-1191.000000, -352.000000)" fill="#4B4D5C">
				<path
					d="M1197.65502,356.012383 L1197.68428,356.518441 L1197.19656,356.456475 C1195.42124,356.218937 1193.87028,355.413376 1192.55342,354.060445 L1191.90962,353.389144 L1191.7438,353.884874 C1191.39263,354.989939 1191.61699,356.156971 1192.34858,356.941877 C1192.73876,357.375641 1192.65096,357.437608 1191.9779,357.179415 C1191.7438,357.096793 1191.53895,357.034827 1191.51944,357.06581 C1191.45116,357.138104 1191.68527,358.077926 1191.87061,358.449724 C1192.12422,358.966109 1192.64121,359.472167 1193.20697,359.771671 L1193.68494,360.009209 L1193.11918,360.019536 C1192.57293,360.019536 1192.55342,360.029864 1192.61195,360.246746 C1192.80704,360.918047 1193.57764,361.63066 1194.43604,361.940491 L1195.04082,362.157373 L1194.51407,362.48786 C1193.73371,362.962935 1192.81679,363.231455 1191.89987,363.252111 C1191.46092,363.262439 1191.1,363.303749 1191.1,363.334733 C1191.1,363.43801 1192.29005,364.016362 1192.98262,364.243571 C1195.06032,364.914873 1197.52821,364.625697 1199.38157,363.479321 C1200.69842,362.663431 1202.01528,361.04198 1202.62981,359.472167 C1202.96147,358.635623 1203.29312,357.107121 1203.29312,356.373853 C1203.29312,355.898778 1203.32238,355.836812 1203.86863,355.268788 C1204.19053,354.938301 1204.49292,354.576831 1204.55145,354.473554 C1204.64899,354.277327 1204.63924,354.277327 1204.14176,354.452898 C1203.31263,354.76273 1203.19557,354.721419 1203.60526,354.256672 C1203.90765,353.926185 1204.26857,353.327178 1204.26857,353.151606 C1204.26857,353.120623 1204.12225,353.172262 1203.95642,353.265211 C1203.78084,353.368488 1203.39066,353.523404 1203.09803,353.616354 L1202.57129,353.791925 L1202.09332,353.45111 C1201.82994,353.265211 1201.45927,353.058657 1201.26418,352.996691 C1200.7667,352.852103 1200.00585,352.872758 1199.55715,353.038002 C1198.33783,353.502749 1197.56723,354.700763 1197.65502,356.012383 Z"
				></path>
			</g>
		</g>
	</svg>
</template>
