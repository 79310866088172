import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5abb5250"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tradist-checkbox" }
const _hoisted_2 = ["checked"]
const _hoisted_3 = {
  key: 0,
  class: "tradist-checkbox__check"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({
      class: ["tradist-checkbox__input", { error: _ctx.error }],
      type: "checkbox",
      checked: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
    }, _ctx.$attrs), null, 16, _hoisted_2),
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}