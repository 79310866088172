<template>
	<svg
		width="18px"
		height="12px"
		viewBox="0 0 18 12"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g
				class="c1-primary"
				transform="translate(-1351.000000, -51.000000)"
				fill="#CBFF17"
				fill-rule="nonzero"
			>
				<g transform="translate(901.000000, 36.000000)">
					<g transform="translate(450.000000, 15.595628)">
						<polygon
							transform="translate(8.625000, 0.500000) scale(-1, 1) translate(-8.625000, -0.500000) "
							points="17.25 1 17.25 0 0 0 0 1"
						></polygon>
						<polygon
							transform="translate(10.750000, 5.500000) scale(-1, 1) translate(-10.750000, -5.500000) "
							points="17.25 6 17.25 5 4.25 5 4.25 6"
						></polygon>
						<polygon
							transform="translate(8.750000, 10.500000) scale(-1, 1) translate(-8.750000, -10.500000) "
							points="17.25 11 17.25 10 0.25 10 0.25 11"
						></polygon>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
