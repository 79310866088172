import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6df33462"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "link-and-button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.linkClick && _ctx.linkClick(...args))),
      class: "link-and-button-group--link"
    }, _toDisplayString(_ctx.linkText), 1),
    _createVNode(_component_BaseButton, {
      fontWeight: 600,
      bgColor: "transparent",
      textColor: "theme",
      onClick: _ctx.buttonClick
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}