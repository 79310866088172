
import { computed, defineComponent } from 'vue';
import BaseInput from '../Base/BaseInput.vue';

export default defineComponent({
	components: { BaseInput },
	name: 'MaterialInputWithLabel',
	inheritAttrs: false,
	props: {
		inputId: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			default: '',
		},
		modelValue: {
			type: String,
			default: '',
			required: true,
		},
		divClasses: {
			type: String,
			default: '',
		},
		labelClasses: {
			type: String,
			default: '',
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '',
		},
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const inputValue = computed<string>({
			get() {
				return props.modelValue;
			},
			set(val) {
				emit('update:modelValue', val);
			},
		});

		return {
			inputValue,
		};
	},
});
