<template>
	<svg
		width="163px"
		height="404px"
		viewBox="0 0 163 404"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
	>
		<g
			stroke="none"
			stroke-width="1"
			fill="none"
			fill-rule="evenodd"
			stroke-dasharray="32,11"
		>
			<g
				class="stroke-primary"
				transform="translate(-914.000000, -158.000000)"
				fill-rule="nonzero"
			>
				<path
					d="M995.4,560.595628 L995.4,319.395628 L1075.8,319.395628 L1075.8,238.995628 L995.4,238.995628 L995.4,158.595628 L915,158.595628 L915,560.595628 L995.4,560.595628 Z"
					id="t"
				></path>
			</g>
		</g>
	</svg>
</template>
