import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsletterArrowIcon = _resolveComponent("NewsletterArrowIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    size: "xs",
    fontSize: "sm",
    textColor: "primary",
    bgColor: "black",
    fontWeight: 500
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NewsletterArrowIcon)
    ]),
    _: 1
  }))
}