
import { computed, defineComponent } from 'vue';
import TradistIcon from '../Icons/TradistIcon.vue';
import HamburgerMenuIcon from '../Icons/HamburgerMenuIcon.vue';
import ThemeSwitcher from '../ThemeSwitcher.vue';
import { useRoute } from 'vue-router';
import { useUser } from '../../composables/useUser';
import NavbarSearchButton from '../Buttons/NavbarSearchButton.vue';
import NavbarUserDropdown from '../Dropdowns/NavbarUserDropdown.vue';
import { useDropdown } from '../../composables/useDropdown';
import HamburgerMenuDropdown from '../Dropdowns/HamburgerMenuDropdown.vue';
import LoginButton from '../Buttons/LoginButton.vue';
import { config } from '../../config';

export default defineComponent({
	components: {
		TradistIcon,
		HamburgerMenuIcon,
		ThemeSwitcher,
		NavbarSearchButton,
		NavbarUserDropdown,
		HamburgerMenuDropdown,
		LoginButton,
	},
	setup() {
		const route = useRoute();

		const isRouteHome = () => {
			return route.path === '/';
		};

		const routeClasses = computed(() => {
			const bgColor = isRouteHome() ? 'transparent' : 'solid';
			return `bg-${bgColor}`;
		});

		const routeReturns = {
			isRouteHome,
			routeClasses,
		};

		const { user, isUserLoggedIn } = useUser();

		const { isUserDropdownOpen, openUserDropdown, closeUserDropdown } =
			useDropdown('User');

		const {
			isHamburgerDropdownOpen,
			openHamburgerDropdown,
			closeHamburgerDropdown,
		} = useDropdown('Hamburger');

		return {
			config,
			...routeReturns,
			user,
			isUserLoggedIn,
			isUserDropdownOpen,
			openUserDropdown,
			closeUserDropdown,
			isHamburgerDropdownOpen,
			openHamburgerDropdown,
			closeHamburgerDropdown,
		};
	},
});
