import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_LoginRegisterDialog = _resolveComponent("LoginRegisterDialog")!
  const _component_BaseToaster = _resolveComponent("BaseToaster")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseButton, {
      class: "mr-8",
      fontWeight: 600,
      bgColor: "black",
      textColor: "white",
      onClick: _ctx.handleLogin
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.loginButtonText), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.config.LOGIN_WITH_METAMASK_ONLY)
      ? (_openBlock(), _createBlock(_component_LoginRegisterDialog, {
          key: 0,
          active: _ctx.isLoginRegisterModalOpen,
          "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoginRegisterModalOpen) = $event))
        }, null, 8, ["active"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BaseToaster, {
      active: _ctx.toaster.visible,
      text: _ctx.toaster.text,
      buttonText: _ctx.toaster.buttonText,
      type: _ctx.toaster.type,
      onClick: _ctx.toaster.action
    }, null, 8, ["active", "text", "buttonText", "type", "onClick"])
  ], 64))
}