import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-764f0c1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown hamburger-menu-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseDivider = _resolveComponent("BaseDivider")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "dropdown__offset",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeDropdown')))
    }),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menu, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: `menu-${menu}`
        }, [
          (index !== 0)
            ? (_openBlock(), _createBlock(_component_BaseDivider, {
                key: 0,
                class: "hamburger-menu-dropdown--divider"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            to: menu.link
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(menu.title), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ], 64))
      }), 128))
    ])
  ]))
}