import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ae677e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "line-through-header" }
const _hoisted_2 = { class: "line-through-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.firstTitle) + " ", 1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.secondTitle), 1)
    ])
  ]))
}