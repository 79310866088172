import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-086b8208"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "line-through-second-header" }
const _hoisted_2 = { class: "line-through-second-header__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h2", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}