import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a8cd193"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "base-toaster"
}
const _hoisted_2 = { class: "base-toaster__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#dialog-target" }, [
    _createElementVNode("div", null, [
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (_ctx.active)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1),
                _createVNode(_component_BaseButton, {
                  isText: "",
                  textColor: _ctx.type,
                  size: "none",
                  fontSize: "sm",
                  fontWeight: 600,
                  bgColor: "transparent",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                  ]),
                  _: 1
                }, 8, ["textColor"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}