import { Ref, ref, nextTick } from 'vue';
import lottie from 'lottie-web';
import loaderData from '../assets/lottie/data.json';

interface ILoader {
	isActive: boolean;
	message: string;
}

const loader = ref<ILoader>({
	isActive: false,
	message: '',
});

const lottieAnimationName = 'loader';

const initializeLoader = () => {
	const loaderTarget = document.getElementById('loader-target');
	lottie.loadAnimation({
		name: lottieAnimationName,
		container: loaderTarget,
		renderer: 'svg',
		loop: true,
		autoplay: true,
		animationData: loaderData,
		rendererSettings: {
			progressiveLoad: false,
			hideOnTransparent: true,
		},
	});
};

const openLoader = (message = '') => {
	if (!loader.value.isActive) {
		loader.value.isActive = true;
		loader.value.message = message;
		nextTick(() => {
			initializeLoader();
		});
	}
};

const changeMessage = (value: string) => {
	loader.value.message = value;
};

const closeLoader = () => {
	lottie.destroy(lottieAnimationName);
	loader.value.message = '';
	loader.value.isActive = false;
};

interface IUseLoaderReturn {
	loader: Ref<ILoader>;
	openLoader: (string?) => void;
	closeLoader: () => void;

	changeMessage: (string) => void;
}

export function useLoader(): IUseLoaderReturn {
	return {
		loader,
		openLoader,
		closeLoader,
		changeMessage,
	};
}
